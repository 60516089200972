import React, { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import styled from 'styled-components';
import Grid from 'styled-components-grid';
import { Padding } from 'styled-components-spacing';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { Box } from '../../components/containers/box';
import { Container } from '../../components/containers/container';
import SideBar from '../../components/navigation/sidebar';
import { HeadlineOne, HeadlineTwo } from '../../components/typography';
import { formatterCounts, formatterPercent, formatterInt, formatterPercentShort } from '../../metrics/formatters';
import { Loader } from '../../components/loader';
import { DataDateInput } from './components/inputs/date';
import { checkPermissions, SCOPES } from '../../utils/permissions';

// Chart config.

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
)

// ChartJS.defaults.color = '#ffffff';
// ChartJS.defaults.borderColor = '#465d6d';
ChartJS.defaults.responsive = true;
ChartJS.defaults.plugins.legend.align = 'end';
ChartJS.defaults.elements.bar.backgroundColor = '#ab8f94';
ChartJS.defaults.scale.grid.display = true;
ChartJS.defaults.scales.linear.ticks.maxTicksLimit = 6;
ChartJS.defaults.scales.linear.ticks.callback = (value, index, ticks) => '$' + formatterInt.format(parseInt(`${value}`));

const barOptions = {
    responsive: true,
    plugins: {
        legend: {
            display: false
        }
    },
    scales: {
        x: {
            ticks: {
                color: "#ffffff",
            },
            grid: {
                color: "#465d6d",
            },
        },
        y: {
            ticks: {
                color: "#ffffff",
            },
            grid: {
                color: "#465d6d",
            },
        }
    },
}

// Helper functions.

const getFutureValuePotential = (type, data, segments) => {
    let actual;
    let benchmark;
    let total;
    switch (type) {
        case 'migration':
            actual = data[segments[0]].previous.count * data[segments[0]].scores.grades[0].raw * data[segments[1]].current.netRevenuePerUser;
            benchmark = data[segments[0]].previous.count * data[segments[0]].scores.grades[0].benchmark * data[segments[1]].current.netRevenuePerUser;
            return benchmark - actual;
        case 'count':
            total = data.SEGMENT_CASUAL.current.count + data.SEGMENT_LOYALIST.current.count + data.SEGMENT_CHEERLEADER.current.count
            actual = total * data[segments[0]].scores.grades[1].raw * data[segments[0]].current.netRevenuePerUser;
            benchmark = total * data[segments[0]].scores.grades[1].benchmark * data[segments[0]].current.netRevenuePerUser;
            return benchmark - actual;
        case 'revenue':
            total = data.SEGMENT_CASUAL.growth.revenueTotalChange + data.SEGMENT_LOYALIST.growth.revenueTotalChange + data.SEGMENT_CHEERLEADER.growth.revenueTotalChange
            actual = (data[segments[0]].current.netRevenue / data[segments[0]].current.revenue) * data[segments[0]].growth.revenueTotalChange;
            benchmark = (data[segments[0]].current.netRevenue / data[segments[0]].current.revenue) * (total * data[segments[0]].scores.grades[2].benchmark);
            return benchmark - actual;
        case 'value':
            actual = data[segments[0]].current.count * (data[segments[0]].scores.grades[3].raw * data.SEGMENT_CASUAL.current.netRevenuePerUser);
            benchmark = data[segments[0]].current.count * (data[segments[0]].scores.grades[3].benchmark * data.SEGMENT_CASUAL.current.netRevenuePerUser);
            return benchmark - actual;
        default:
            return 0;
    }
}

const getTotalFutureValuePotential = (type, data) => {
    switch (type) {
        case 'migration':
            return Object.keys(data[type]).map(e => data[type][e].futureValuePotential).reduce((a, e) => e > 0 ? a + e : a, 0);
        case 'count':
        case 'revenue':
            return Object.keys(data[type]).filter(e => data[type][e]).map(e => data[type][e].futureValuePotential).reduce((a, e) => a + e, 0)
        case 'value':
            return Object.keys(data[type]).filter(e => data[type][e]).map(e => data[type][e].futureValuePotential).reduce((a, e) => e > 0 ? a + e : a, 0);
        default:
            return 0;
    }
}

// Helper components.

const StyledHeaderBox = styled.div`
    background: #334450;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 50px;
    margin-bottom: 20px;
`;

const StyledTextBox = styled.div`
    max-width: 600px;
    margin-bottom: 20px;
    & > * {
        margin: 0px 0px 1em;
    }
    & > *:first-child {
        font-weight: 700;
    }
`;

const StyledChartBox = styled.div`
    background: #334450;
    padding: 20px;
`;

const StyledTabList = styled(TabList)`
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
`;

const StyledTab = styled(Tab)`
    flex: 1;
    background: #ffffff;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    text-align: center;
    cursor: pointer;
    outline: 0px;
    :not(:last-child) {
        margin-right: 10px;
    }
    &.react-tabs__tab--selected {
        background: linear-gradient(to right top, #ab8f94, #9ac0c0);
        color: #ffffff;
        font-weight: 700;
    }
`;

const StyledTabPanels = styled.div`
    position: relative;
    z-index: 1;
`;

const StyledTabPanel = styled(TabPanel)`
    &.react-tabs__tab-panel--selected > * {
        padding: 50px;
        margin-bottom: 20px;
    }
`;

const StyledScoreWrap = styled.div`
    position: relative;
    height: 0;
    padding-bottom: 100%;
    z-index: 1;
`;

const StyledScoreInner = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right top, #ab8f94, #9ac0c0);
    border-radius: 50%;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledScore = styled(StyledScoreInner)`
    background: #334450;
    color: #ffffff;
    font-size: 3em;
    font-weight: 700;
    margin: 30px;
`;

const StyledScoreTitle = styled.div`
    font-weight: 700;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 20px;
`;

const StyledScoreSubtitle = styled.div`
    text-align: center;
    margin-top: 20px;
`;

function Score(props) {
    return (
        <StyledScoreWrap>
            <StyledScoreInner>
                <StyledScore style={props.size === 'small' ? {fontSize: "1em", margin: 10} : {}}>
                    {(props.score || props.score === 0) ? props.score : <Loader />}
                </StyledScore>
            </StyledScoreInner>
        </StyledScoreWrap>
    )
}

function ScoreCard(props) {
    if (props.placeholder) {
        return (
            <div style={{opacity:0.5}}>
                <StyledScoreTitle>{props.title}</StyledScoreTitle>
                <Score score={props.score} />
            </div>
        )
    }
    return (
        <>
            <StyledScoreTitle>{props.title}</StyledScoreTitle>
            <Score score={props.score} />
            <StyledScoreSubtitle>
                {props.subtitle}
                {props.subvalue && <div>{props.subvalue}</div>}
            </StyledScoreSubtitle>
        </>
    )
}

// Default component.

function ExecutiveSummary(props) {

    const [quantData]: any = useGlobal('quantData');
    const [quantDataLoading]: any = useGlobal('quantDataLoading');

    const [summaryData, setSummaryData]: any = useState({})

    useEffect(() => {
        const url = process.env.REACT_APP_API
            ? `${process.env.REACT_APP_API}/unstable/orders`
            : 'http://localhost:3000/unstable/orders'
        fetch(url)
            .then((response) => {
                console.log(response)
            })
    }, [])

    useEffect(() => {
        if (quantData) {
            if (!quantData.SEGMENT_PROSPECTS.scores.grades.length) {
                quantData.SEGMENT_PROSPECTS.scores.grades.push({
                    weighted: 0,
                    raw: 0
                })
            }
            setSummaryData(state => ({
                migration: {
                    prospects: {
                        score: quantData.SEGMENT_PROSPECTS.scores.grades[0].weighted,
                        raw: quantData.SEGMENT_PROSPECTS.scores.grades[0].raw,
                        futureValuePotential: getFutureValuePotential('migration', quantData, ["SEGMENT_PROSPECTS", "SEGMENT_CASUAL"]),
                    },
                    casuals: {
                        score: quantData.SEGMENT_CASUAL.scores.grades[0].weighted,
                        raw: quantData.SEGMENT_CASUAL.scores.grades[0].raw,
                        futureValuePotential: getFutureValuePotential('migration', quantData, ["SEGMENT_CASUAL", "SEGMENT_LOYALIST"]),
                    },
                    loyalists: {
                        score: quantData.SEGMENT_LOYALIST.scores.grades[0].weighted,
                        raw: quantData.SEGMENT_LOYALIST.scores.grades[0].raw,
                        futureValuePotential: getFutureValuePotential('migration', quantData, ["SEGMENT_LOYALIST", "SEGMENT_CHEERLEADER"]),
                    },
                    cheerleaders: {
                        score: quantData.SEGMENT_CHEERLEADER.scores.grades[0].weighted,
                        raw: quantData.SEGMENT_CHEERLEADER.scores.grades[0].raw,
                        futureValuePotential: getFutureValuePotential('migration', quantData, ["SEGMENT_CHEERLEADER", "SEGMENT_CHEERLEADER"]),
                    },
                },
                count: {
                    prospects: null,
                    casuals: {
                        score: quantData.SEGMENT_CASUAL.scores.grades[1].weighted,
                        raw: quantData.SEGMENT_CASUAL.scores.grades[1].raw,
                        futureValuePotential: getFutureValuePotential('count', quantData, ["SEGMENT_CASUAL"]),
                    },
                    loyalists: {
                        score: quantData.SEGMENT_LOYALIST.scores.grades[1].weighted,
                        raw: quantData.SEGMENT_LOYALIST.scores.grades[1].raw,
                        futureValuePotential: getFutureValuePotential('count', quantData, ["SEGMENT_LOYALIST"]),
                    },
                    cheerleaders: {
                        score: quantData.SEGMENT_CHEERLEADER.scores.grades[1].weighted,
                        raw: quantData.SEGMENT_CHEERLEADER.scores.grades[1].raw,
                        futureValuePotential: getFutureValuePotential('count', quantData, ["SEGMENT_CHEERLEADER"]),
                    },
                },
                revenue: {
                    prospects: null,
                    casuals: {
                        score: quantData.SEGMENT_CASUAL.scores.grades[2].weighted,
                        raw: quantData.SEGMENT_CASUAL.scores.grades[2].raw,
                        futureValuePotential: getFutureValuePotential('revenue', quantData, ["SEGMENT_CASUAL"]),
                    },
                    loyalists: {
                        score: quantData.SEGMENT_LOYALIST.scores.grades[2].weighted,
                        raw: quantData.SEGMENT_LOYALIST.scores.grades[2].raw,
                        futureValuePotential: getFutureValuePotential('revenue', quantData, ["SEGMENT_LOYALIST"]),
                    },
                    cheerleaders: {
                        score: quantData.SEGMENT_CHEERLEADER.scores.grades[2].weighted,
                        raw: quantData.SEGMENT_CHEERLEADER.scores.grades[2].raw,
                        futureValuePotential: getFutureValuePotential('revenue', quantData, ["SEGMENT_CHEERLEADER"]),
                    },
                },
                value: {
                    prospects: null,
                    casuals: null,
                    loyalists: {
                        score: quantData.SEGMENT_LOYALIST.scores.grades[3].weighted,
                        raw: quantData.SEGMENT_LOYALIST.scores.grades[3].raw,
                        futureValuePotential: getFutureValuePotential('value', quantData, ["SEGMENT_LOYALIST"]),
                    },
                    cheerleaders: {
                        score: quantData.SEGMENT_CHEERLEADER.scores.grades[3].weighted,
                        raw: quantData.SEGMENT_CHEERLEADER.scores.grades[3].raw,
                        futureValuePotential: getFutureValuePotential('value', quantData, ["SEGMENT_CHEERLEADER"]),
                    },
                },
            }))
        }
    }, [quantData])

    return (
        <Container>
            <SideBar active="home" brand={props.match.params.brand} />
            <Grid>
                <Grid.Unit size={{ sm: 1, md: 1 }}>
                    <Padding all={3}>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                            <HeadlineOne>Executive Summary</HeadlineOne>
                            <DataDateInput />
                        </div>
                        <StyledHeaderBox>
                            <HeadlineTwo style={{margin:0, color: "#fff", fontWeight: 700}}>Brand Equity Index</HeadlineTwo>
                            <div style={{flex: "0 0 65px"}}>
                                <Score score={!quantDataLoading && Math.round(quantData.scores.total)} size="small" />
                            </div>
                        </StyledHeaderBox>
                        <Tabs>
                            <StyledTabList>
                                <StyledTab>Overall Score</StyledTab>
                                <StyledTab>Migration Rate</StyledTab>
                                <StyledTab>Segment Count</StyledTab>
                                <StyledTab>Segment Revenue</StyledTab>
                                <StyledTab>Individual Value</StyledTab>
                            </StyledTabList>
                            <StyledTabPanels>
                                <StyledTabPanel>
                                    <Box>
                                        <Grid>
                                            <Grid.Unit size={{ sm: 9 / 12 }}>
                                                <StyledTextBox>
                                                    <p>Actual Overall Score</p>
                                                </StyledTextBox>
                                            </Grid.Unit>
                                            <Grid.Unit size={{ sm: 3 / 12 }}>
                                                <Padding horizontal={3}>
                                                    <ScoreCard 
                                                        title="Actual Score"
                                                        score={!quantDataLoading && Math.round(quantData.scores.total)}
                                                        subtitle="of 500" />
                                                </Padding>
                                            </Grid.Unit>
                                        </Grid>
                                    </Box>
                                    {checkPermissions([SCOPES.canViewFutureValue], props.match.params.brand) && (
                                        <Box>
                                            <StyledTextBox>
                                                <p>Future Value Potential</p>
                                                <p>Optimizing Overall Customer Activation; i.e. meeting the Optimal Target for Acquisition, Migration, Retention, Segment Counts, Segment Revenue Distribution, and Individual Value Ratios has a tangible impact on overall profit dollars.</p>
                                                {!!Object.keys(summaryData).length && (Object.keys(summaryData).map(e => getTotalFutureValuePotential(e, summaryData)).reduce((a, e) => a + e, 0) > 0)
                                                    ? (
                                                        <>
                                                        <p>By optimizing to benchmarks there is an annualized potential additional <strong>${formatterInt.format(Object.keys(summaryData).map(e => getTotalFutureValuePotential(e, summaryData)).reduce((a, e) => a + e, 0))}</strong> of profit dollars.</p>
                                                        <p>Meaning, this increase in profit is over the time period measured.</p>
                                                        </>
                                                    )
                                                    : (<p>While further optimization is always possible, your combined performance in all categories exceeds industry benchmarks.</p>)
                                            }
                                            </StyledTextBox>
                                            {!quantData && (
                                                <Loader />
                                            )}
                                            {!!Object.keys(summaryData).length && (
                                                <StyledChartBox>
                                                    <Bar options={barOptions} data={{
                                                        labels: ['Migration Rate', 'Segment Count', 'Segment Revenue', 'Individual Value'],
                                                        datasets: [{
                                                            label: 'Potential',
                                                            data: Object.keys(summaryData).map(e => getTotalFutureValuePotential(e, summaryData))
                                                        }]
                                                    }} />
                                                </StyledChartBox>
                                            )}
                                        </Box>
                                    )}
                                    <Box>
                                        <Grid>
                                            <Grid.Unit size={{ sm: 9 / 12 }}>
                                                <StyledTextBox>
                                                    <p>Optimal Overall Target Score</p>
                                                    <p>An overall Brand Equity Index of 500 (on a scale of 0 to 500) is the Optimal Target Score.</p>
                                                    <p>Scoring a 500 means that your brand's performance is optimal relative to the industry standards that predict profitability and growth.</p>
                                                    <p>Meaning the entirety of your Brand Ecosystem effectively introduces itself to a potential customer and that customer matriculates to a state of 'Loyal' purchasing behavior and 'Brand Advocacy;' becoming a Cheerleader.</p>
                                                </StyledTextBox>
                                            </Grid.Unit>
                                            <Grid.Unit size={{ sm: 3 / 12 }}>
                                                <Padding horizontal={3}>
                                                    <ScoreCard
                                                        title="Optimal Score"
                                                        score="500"
                                                        subtitle="Brand Equity Target Realized" />
                                                </Padding>
                                            </Grid.Unit>
                                        </Grid>
                                    </Box>
                                </StyledTabPanel>
                                <StyledTabPanel>
                                    <Box>
                                        <StyledTextBox>
                                            <p>Actual Rates and Scores</p>
                                            <p>During the period measured, this is how your Brand Ecosystem performed. Meaning, this is the rate (pace, likelihood) at which Cheerleaders are being created and retained.</p>
                                            <p>These are your Scores for Migration for the time period measured. These SCORES are relative to the industry benchmarks and are to be used as a means to diagnose where best to focus on the optimization of the user experience to optimize profitability.</p>
                                        </StyledTextBox>
                                        {summaryData.migration && (
                                            <div style={{minHeight: 600, maxHeight: 600, minWidth: 600, maxWidth: 600, margin: "auto", position: "relative"}}>
                                                <div style={{width: 200, position: "absolute", top: 0, left: "50%", transform: "translateX(-50%)"}}>
                                                    <Score score={!quantDataLoading && summaryData.migration.prospects.score} />
                                                </div>
                                                <div style={{width: 200, position: "absolute", top: "50%", right: 0, transform: "translateY(-50%)"}}>
                                                    <Score score={!quantDataLoading && summaryData.migration.casuals.score} />
                                                </div>
                                                <div style={{width: 200, position: "absolute", bottom: 0, left: "50%", transform: "translateX(-50%)"}}>
                                                    <Score score={!quantDataLoading && summaryData.migration.loyalists.score} />
                                                </div>
                                                <div style={{width: 200, position: "absolute", top: "50%", left: 0, transform: "translateY(-50%)"}}>
                                                    <Score score={!quantDataLoading && summaryData.migration.cheerleaders.score} />
                                                </div>
                                                <div style={{position: "absolute", top: "18%", right: "18%"}}>
                                                    <div style={{width: 75, position: "relative"}}>
                                                        <Score score={!quantDataLoading && formatterPercentShort.format(summaryData.migration.prospects.raw)} size="small" />
                                                        <div style={{position: "absolute", top: "50%", left: "100%", transform: "translateY(-50%)", paddingLeft: 10, whiteSpace: "nowrap"}}>Prospects to Casuals</div>
                                                        <div style={{position: "absolute", top: -6, left: -3, width: "104%", transform: "rotate(-180deg)"}}>
                                                            <svg viewBox="0 0 115.16 121.13"><path fill="#9ac1c1" d="M115.03,119.41c-.35,1.05-1.32,1.72-2.37,1.72-.26,0-.53-.04-.78-.12-.67-.22-16.56-5.55-37.37-22.42C55.77,83.4,29.43,55.6,9.43,9l-4.56,13.61c-.35,1.05-1.32,1.71-2.37,1.71-.26,0-.53-.04-.79-.13-1.31-.44-2.02-1.85-1.58-3.16L6.6,1.7c.44-1.31,1.85-2.01,3.16-1.57L29.09,6.6c1.31,.43,2.01,1.85,1.57,3.16-.44,1.31-1.85,2.02-3.16,1.58L13.91,6.79C51.72,95.11,112.82,116.05,113.44,116.26c1.31,.43,2.02,1.84,1.59,3.15Z"/></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{position: "absolute", bottom: "18%", right: "18%"}}>
                                                    <div style={{width: 75, position: "relative"}}>
                                                        <Score score={!quantDataLoading && formatterPercentShort.format(summaryData.migration.casuals.raw)} size="small" />
                                                        <div style={{position: "absolute", top: "50%", left: "100%", transform: "translateY(-50%)", paddingLeft: 10, whiteSpace: "nowrap"}}>Casuals to Loyalists</div>
                                                        <div style={{position: "absolute", top: -6, left: 0, width: "105%", transform: "rotate(-90deg)"}}>
                                                            <svg viewBox="0 0 115.16 121.13"><path fill="#9ac1c1" d="M115.03,119.41c-.35,1.05-1.32,1.72-2.37,1.72-.26,0-.53-.04-.78-.12-.67-.22-16.56-5.55-37.37-22.42C55.77,83.4,29.43,55.6,9.43,9l-4.56,13.61c-.35,1.05-1.32,1.71-2.37,1.71-.26,0-.53-.04-.79-.13-1.31-.44-2.02-1.85-1.58-3.16L6.6,1.7c.44-1.31,1.85-2.01,3.16-1.57L29.09,6.6c1.31,.43,2.01,1.85,1.57,3.16-.44,1.31-1.85,2.02-3.16,1.58L13.91,6.79C51.72,95.11,112.82,116.05,113.44,116.26c1.31,.43,2.02,1.84,1.59,3.15Z"/></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{position: "absolute", bottom: "18%", left: "18%"}}>
                                                    <div style={{width: 75, position: "relative"}}>
                                                        <Score score={!quantDataLoading && formatterPercentShort.format(summaryData.migration.loyalists.raw)} size="small" />
                                                        <div style={{position: "absolute", top: "50%", right: "100%", transform: "translateY(-50%)", paddingRight: 10, whiteSpace: "nowrap"}}>Loyalists to Cheerleaders</div>
                                                        <div style={{position: "absolute", top: -6, left: 0, width: "105%"}}>
                                                            <svg viewBox="0 0 115.16 121.13"><path fill="#9ac1c1" d="M115.03,119.41c-.35,1.05-1.32,1.72-2.37,1.72-.26,0-.53-.04-.78-.12-.67-.22-16.56-5.55-37.37-22.42C55.77,83.4,29.43,55.6,9.43,9l-4.56,13.61c-.35,1.05-1.32,1.71-2.37,1.71-.26,0-.53-.04-.79-.13-1.31-.44-2.02-1.85-1.58-3.16L6.6,1.7c.44-1.31,1.85-2.01,3.16-1.57L29.09,6.6c1.31,.43,2.01,1.85,1.57,3.16-.44,1.31-1.85,2.02-3.16,1.58L13.91,6.79C51.72,95.11,112.82,116.05,113.44,116.26c1.31,.43,2.02,1.84,1.59,3.15Z"/></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{position: "absolute", top: "28%", left: "18%"}}>
                                                    <div style={{width: 75, position: "relative"}}>
                                                        <Score score={!quantDataLoading && formatterPercentShort.format(summaryData.migration.cheerleaders.raw)} size="small" />
                                                        <div style={{position: "absolute", bottom: "100%", right: "100%", whiteSpace: "nowrap"}}>Cheerleader Retention</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Box>
                                    {checkPermissions([SCOPES.canViewFutureValue], props.match.params.brand) && (
                                        <Box>
                                            <StyledTextBox>
                                                <p>Future Value Potential</p>
                                                <p>This is a graph of how much “profit” is currently being generated versus how much “profit” could be generated if the Customer Activation Cycle were to be optimized to the industry benchmarks for Migration.</p>
                                                <p>Meeting the Optimal Targets for Migration, has a tangible impact on profit dollars.</p>
                                                {summaryData.migration && (getTotalFutureValuePotential('migration', summaryData) > 0) 
                                                    ? (<p>Optimizing to benchmarks could potentially result in an additional <strong>${formatterInt.format(getTotalFutureValuePotential('migration', summaryData))}</strong> of profit dollars during the time period measured.</p>)
                                                    : (<p>While further optimization is always possible, your combined performance in this category exceeds industry benchmarks.</p>)
                                                }
                                            </StyledTextBox>
                                            {summaryData.migration && (
                                                <StyledChartBox>
                                                    <Bar options={barOptions} data={{
                                                        labels: ['Prospects to Casuals', 'Casuals to Loyalists', 'Loyalists to Cheerleaders', 'Retaining Cheerleaders'],
                                                        datasets: [{
                                                            label: 'Potential',
                                                            data: Object.keys(summaryData.migration).map(e => summaryData.migration[e].futureValuePotential)
                                                        }]
                                                    }} />
                                                </StyledChartBox>
                                            )}
                                        </Box>
                                    )}
                                    <Box>
                                        <StyledTextBox>
                                            <p>Optimal Targets</p>
                                            <p>The "Migration Targets" are standard industry performance benchmarks in the journey your Customers take from Prospect to Cheerleader.</p>
                                            <p>On a scale of 0 to 500, a Score of 500 is Optimal and indicates that the onboarding and Migration of customers is meeting the industry standard benchmarks.</p>
                                            <p>This means that by realizing these benchmarks your Brand Ecosystem user experience would efficiently "Migrate" Customers from each Segment, from Prospects to Casuals to Loyalists to Cheerleaders.</p>
                                        </StyledTextBox>
                                        <Grid>
                                            {summaryData.migration && Object.keys(summaryData.migration).map((e, i) => (
                                                <Grid.Unit key={i} size={{ sm: 3 / 12 }}>
                                                    <Padding horizontal={3}>
                                                        <ScoreCard
                                                            title={e}
                                                            score="500"
                                                            subtitle={e === 'prospects' ? 'Acquisition Rate' : e === 'cheerleaders' ? 'Retention Rate' : 'Migration Rate'}
                                                            subvalue={e === 'prospects' ? '1.5%' : e === 'casuals' ? '15%' : e === 'loyalists' ? '20%' : '80%'} />
                                                    </Padding>
                                                </Grid.Unit>
                                            ))}
                                        </Grid>
                                    </Box>
                                </StyledTabPanel>
                                <StyledTabPanel>
                                    <Box>
                                        <StyledTextBox>
                                            <p>Actual Performance and Scores</p>
                                        </StyledTextBox>
                                        <Grid>
                                            {summaryData.count && Object.keys(summaryData.count).map((e, i) => (
                                                <Grid.Unit key={i} size={{ sm: 3 / 12 }}>
                                                    <Padding horizontal={3}>
                                                        {!summaryData.count[e] && (
                                                            <ScoreCard
                                                                placeholder="true"
                                                                title={e}
                                                                score="N/A" />
                                                        )}
                                                        {summaryData.count[e] && (
                                                            <ScoreCard
                                                                title={e}
                                                                score={!quantDataLoading && summaryData.count[e].score}
                                                                subtitle="Count"
                                                                subvalue={!quantDataLoading && formatterPercent.format(summaryData.count[e].raw)} />
                                                        )}
                                                    </Padding>
                                                </Grid.Unit>
                                            ))}
                                        </Grid>
                                    </Box>
                                    {checkPermissions([SCOPES.canViewFutureValue], props.match.params.brand) && (
                                        <Box>
                                            <StyledTextBox>
                                                <p>Future Value Potential</p>
                                                <p>Meeting the Optimal Target for Segment Counts, has a tangible impact on profit dollars.</p>
                                                {summaryData.count && (getTotalFutureValuePotential('count', summaryData) > 0)
                                                    ? (<p>By optimizing to benchmarks there is an additional <strong>${formatterInt.format(getTotalFutureValuePotential('count', summaryData))}</strong> of potential profit dollars during the time period measured.</p>)
                                                    : (<p>While further optimization is always possible, your combined performance in this category exceeds industry benchmarks.</p>)
                                                }
                                            </StyledTextBox>
                                            {summaryData.count && (
                                                <StyledChartBox>
                                                    <Bar options={barOptions} data={{
                                                        labels: ['Casuals', 'Loyalists', 'Cheerleaders'],
                                                        datasets: [{
                                                            label: 'Potential',
                                                            data: Object.keys(summaryData.count).filter(e => summaryData.count[e]).map(e => summaryData.count[e].futureValuePotential)
                                                        }]
                                                    }} />
                                                </StyledChartBox>
                                            )}
                                        </Box>
                                    )}
                                    <Box>
                                        <StyledTextBox>
                                            <p>Optimal Targets</p>
                                            <p>500 is the Optimal Target Score for Segment Counts. This means that your Brand Ecosystem user experience balances an influx of new customers that are Migrating efficiently to become Cheerleaders and then retains those Cheerleaders long-term.</p>
                                        </StyledTextBox>
                                        <Grid>
                                            {summaryData.count && Object.keys(summaryData.count).map((e, i) => (
                                                <Grid.Unit key={i} size={{ sm: 3 / 12 }}>
                                                    <Padding horizontal={3}>
                                                        {!summaryData.count[e] && (
                                                            <ScoreCard
                                                                placeholder="true"
                                                                title={e}
                                                                score="N/A" />
                                                        )}
                                                        {summaryData.count[e] && (
                                                            <ScoreCard
                                                                title={e}
                                                                score="500"
                                                                subtitle="Count"
                                                                subvalue={e === 'casuals' ? '70%' : e === 'loyalists' ? '20%' : '10%'} />
                                                        )}
                                                    </Padding>
                                                </Grid.Unit>
                                            ))}
                                        </Grid>
                                    </Box>
                                </StyledTabPanel>
                                <StyledTabPanel>
                                    <Box>
                                        <StyledTextBox>
                                            <p>Actual Performance and Scores</p>
                                        </StyledTextBox>
                                        <Grid>
                                            {summaryData.revenue && Object.keys(summaryData.revenue).map((e, i) => (
                                                <Grid.Unit key={i} size={{ sm: 3 / 12 }}>
                                                    <Padding horizontal={3}>
                                                        {!summaryData.revenue[e] && (
                                                            <ScoreCard
                                                                placeholder="true"
                                                                title={e}
                                                                score="N/A" />
                                                        )}
                                                        {summaryData.revenue[e] && (
                                                            <ScoreCard
                                                                title={e}
                                                                score={!quantDataLoading && summaryData.revenue[e].score}
                                                                subtitle="Revenue"
                                                                subvalue={!quantDataLoading && formatterPercent.format(summaryData.revenue[e].raw)} />
                                                        )}
                                                    </Padding>
                                                </Grid.Unit>
                                            ))}
                                        </Grid>
                                    </Box>
                                    {checkPermissions([SCOPES.canViewFutureValue], props.match.params.brand) && (
                                        <Box>
                                            <StyledTextBox>
                                                <p>Future Value Potential</p>
                                                <p>Meeting the Optimal Target for Segment Revenue, has a tangible impact on profit dollars.</p>
                                                {summaryData.revenue && (getTotalFutureValuePotential('revenue', summaryData) > 0)
                                                    ? (<p>By optimizing to benchmarks there is an additional <strong>${formatterInt.format(getTotalFutureValuePotential('revenue', summaryData))}</strong> of potential profit dollars during the time period measured.</p>)
                                                    : (<p>While further optimization is always possible, your combined performance in this category exceeds industry benchmarks.</p>)
                                                }
                                            </StyledTextBox>
                                            {summaryData.revenue && (
                                                <StyledChartBox>
                                                    <Bar options={barOptions} data={{
                                                        labels: ['Casuals', 'Loyalists', 'Cheerleaders'],
                                                        datasets: [{
                                                            label: 'Potential',
                                                            data: Object.keys(summaryData.revenue).filter(e => summaryData.revenue[e]).map(e => summaryData.revenue[e].futureValuePotential)
                                                        }]
                                                    }} />
                                                </StyledChartBox>
                                            )}
                                        </Box>
                                    )}
                                    <Box>
                                        <StyledTextBox>
                                            <p>Optimal Targets</p>
                                            <p>500 is the Optimal Target Score for Segment Revenue. This means that your Brand Ecosystem user experience balances revenue across segments, from lower value Casuals to higher value Cheerleaders.</p>
                                        </StyledTextBox>
                                        <Grid>
                                            {summaryData.revenue && Object.keys(summaryData.revenue).map((e, i) => (
                                                <Grid.Unit key={i} size={{ sm: 3 / 12 }}>
                                                    <Padding horizontal={3}>
                                                        {!summaryData.revenue[e] && (
                                                            <ScoreCard
                                                                placeholder="true"
                                                                title={e}
                                                                score="N/A" />
                                                        )}
                                                        {summaryData.revenue[e] && (
                                                            <ScoreCard
                                                                title={e}
                                                                score="500"
                                                                subtitle="Revenue"
                                                                subvalue="33%" />
                                                        )}
                                                    </Padding>
                                                </Grid.Unit>
                                            ))}
                                        </Grid>
                                    </Box>
                                </StyledTabPanel>
                                <StyledTabPanel>
                                    <Box>
                                        <StyledTextBox>
                                            <p>Actual Individual (Net) Value of each segment</p>
                                            <p>Casuals: ${quantData && formatterInt.format(quantData.SEGMENT_CASUAL.current.netRevenuePerUser)}</p>
                                            <p>Loyalists: ${quantData && formatterInt.format(quantData.SEGMENT_LOYALIST.current.netRevenuePerUser)}</p>
                                            <p>Cheerleaders: ${quantData && formatterInt.format(quantData.SEGMENT_CHEERLEADER.current.netRevenuePerUser)}</p>
                                        </StyledTextBox>
                                        <Grid>
                                            {summaryData.value && Object.keys(summaryData.value).map((e, i) => (
                                                <Grid.Unit key={i} size={{ sm: 3 / 12 }}>
                                                    <Padding horizontal={3}>
                                                        {!summaryData.value[e] && (
                                                            <ScoreCard
                                                                placeholder="true"
                                                                title={e}
                                                                score="N/A" />
                                                        )}
                                                        {summaryData.value[e] && (
                                                            <ScoreCard
                                                                title={e}
                                                                score={!quantDataLoading && summaryData.value[e].score}
                                                                subtitle="Individual Value Ratio"
                                                                subvalue={!quantDataLoading && `${formatterCounts.format(summaryData.value[e].raw)}:1`} />
                                                        )}
                                                    </Padding>
                                                </Grid.Unit>
                                            ))}
                                        </Grid>
                                    </Box>
                                    {checkPermissions([SCOPES.canViewFutureValue], props.match.params.brand) && (
                                        <Box>
                                            <StyledTextBox>
                                                <p>Future Value Potential</p>
                                                <p>Meeting the Optimal Target for the Individual Value Ratios, has a tangible impact on profit dollars.</p>
                                                {summaryData.value && (getTotalFutureValuePotential('value', summaryData) > 0)
                                                    ? (<p>By optimizing to benchmarks there is an additional <strong>${formatterInt.format(getTotalFutureValuePotential('value', summaryData))}</strong> of potential profit dollars during the time period measured.</p>)
                                                    : (<p>While further optimization is always possible, your combined performance in this category exceeds industry benchmarks.</p>)
                                                }
                                            </StyledTextBox>
                                            {summaryData.value && (
                                                <StyledChartBox>
                                                    <Bar options={barOptions} data={{
                                                        labels: ['Loyalists', 'Cheerleaders'],
                                                        datasets: [{
                                                            label: 'Potential',
                                                            data: Object.keys(summaryData.value).filter(e => summaryData.value[e]).map(e => summaryData.value[e].futureValuePotential)
                                                        }]
                                                    }} />
                                                </StyledChartBox>
                                            )}
                                        </Box>
                                    )}
                                    <Box>
                                        <StyledTextBox>
                                            <p>Optimal Targets</p>
                                            <p>500 is the Optimal Target Score for Individual Segment Value. This means that your Brand Ecosystem user experience realizes maximum profitability from your most valuable segments (Loyalists and Cheerleaders) either by increased frequency of purchase or increased average order value relative to Casuals.</p>
                                        </StyledTextBox>
                                        <Grid>
                                            {summaryData.value && Object.keys(summaryData.value).map((e, i) => (
                                                <Grid.Unit key={i} size={{ sm: 3 / 12 }}>
                                                    <Padding horizontal={3}>
                                                        {!summaryData.value[e] && (
                                                            <ScoreCard
                                                                placeholder="true"
                                                                title={e}
                                                                score="N/A" />
                                                        )}
                                                        {summaryData.value[e] && (
                                                            <ScoreCard
                                                                title={e}
                                                                score="500"
                                                                subtitle="Individual Value Ratio"
                                                                subvalue={e === 'loyalists' ? '3:1' : '12:1'} />
                                                        )}
                                                    </Padding>
                                                </Grid.Unit>
                                            ))}
                                        </Grid>
                                    </Box>
                                </StyledTabPanel>
                            </StyledTabPanels>
                        </Tabs>
                    </Padding>
                </Grid.Unit>
            </Grid>
        </Container>
    )
}

export default ExecutiveSummary;
import * as React from "react";
import { useState, useEffect } from "react";
import AdminScreen from "../../components/admin/containers/screen";
import { useQuery, useMutation } from "react-apollo-hooks";
import Grid from "styled-components-grid";
import { Padding } from "styled-components-spacing";
import { Box } from "../../components/containers/box";
import TextInput from "../../components/admin/inputs/text";
import notify from "../../utils/notify";
import { ButtonHollow } from "../../components/buttons";
import { useGlobal } from "reactn";
import { useForm } from "react-hook-form";
import { LOAD_BRAND_SHOPIFY_CONFIG, UPDATE_DOC } from "../../utils/gql";

export function ShopifyConfig(props) {
  const [global, setGlobal] = useGlobal();
  const mutation = useMutation(UPDATE_DOC);
  const [configStatus, setConfigStaus] = useState(null);
  const [isConfigured, setIsConfigured] = useState(false);

  const { register, getValues, watch } = useForm({
    defaultValues: {
      myshopifyDomain: props.brand.shopifyConfig
        ? props.brand.shopifyConfig.myshopifyDomain
        : "",
      accessToken: "",
      shopifyApiKey: "",
      shopifyApiSecret: "",
    },
  });

  const enteredShopifyDomain = watch("myshopifyDomain");
  const enteredAccessToken = watch("accessToken");
  const enteredShopifyApiKey = watch("shopifyApiKey");
  const enteredShopifyApiSecret = watch("shopifyApiSecret");

  useEffect(() => {
    if (props.brand.shopifyConfig) {
      const { myshopifyDomain } = props.brand.shopifyConfig;
      if (myshopifyDomain) {
        checkKeys(myshopifyDomain);
      }
    }
  }, [props.brand.shopifyConfig]);

  function checkKeys(myshopifyDomain) {
    fetch(
      (process.env.REACT_APP_API
        ? process.env.REACT_APP_API
        : "http://localhost:3000") + "/shopify/verify",
      {
        method: "POST",
        headers: {
          "Content-Type": "Application/JSON",
        },
        body: JSON.stringify({
          myshopifyDomain,
          brandId: props.brand.id,
        }),
      }
    ).then(async (response) => {
      if (response.ok) {
        setIsConfigured(true);
      }
      const message = await response.text();
      console.log(message);
      setConfigStaus(message);
    });
  }

  function getOrderData() {
    const environment = window.location.hostname === 'theplatform.compassandnail.com'
      ? 'production'
      : 'staging';
    const values = getValues();
    fetch(
      (process.env.REACT_APP_API
        ? process.env.REACT_APP_API
        : "http://localhost:3000") + "/shopify/bulk/run",
      {
        method: "POST",
        headers: {
          "Content-Type": "Application/JSON",
        },
        body: JSON.stringify({
          myshopifyDomain: values.myshopifyDomain,
          brandId: props.brand.id,
          environment: environment
        }),
      }
    ).then(async (response) => {
      const message = await response.text();
      if (response.ok) {
        notify(setGlobal, message);
        return;
      }
      notify(setGlobal, message, "error");
    });
  }

  async function save() {
    const values = getValues();
    try {
      const validTokenResponse = await fetch(
        (process.env.REACT_APP_API
          ? process.env.REACT_APP_API
          : "http://localhost:3000") + "/shopify/config",
        {
          method: "POST",
          headers: {
            "Content-Type": "Application/JSON",
          },
          body: JSON.stringify({
            shop: values.myshopifyDomain,
            accessToken: values.accessToken,
            shopifyApiKey: values.shopifyApiKey,
            shopifyApiSecret: values.shopifyApiSecret,
            brandId: props.brand.id,
          }),
        }
      );

      if (validTokenResponse.ok) {
        const id =
          props.brand.shopifyConfig && props.brand.shopifyConfig.id
            ? props.brand.shopifyConfig.id
            : null;
        const type =
          props.brand.shopifyConfig && props.brand.shopifyConfig.type
            ? props.brand.shopifyConfig.type
            : "SHOPIFY_CONFIG";

        const response: any = await mutation({
          variables: {
            input: {
              brand: props.brand.id,
              id,
              type,
              contents: JSON.stringify({
                myshopifyDomain: values.myshopifyDomain,
              }),
            },
          },
        });
        if (response.data.updateDoc) {
          notify(setGlobal, "Saved Shopify Config");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } else {
        notify(setGlobal, "Error invalid credentials", "error");
      }
    } catch (error) {
      console.log(error);
      notify(setGlobal, "Error with request", "error");
    }
  }

  const checkBulkOpStatus = async () => {
    const values = getValues();

    const res = await fetch(
      (process.env.REACT_APP_API
        ? process.env.REACT_APP_API
        : "http://localhost:3000") + "/shopify/bulk/status",
      {
        method: "POST",
        headers: {
          "Content-Type": "Application/JSON",
        },
        body: JSON.stringify({
          myshopifyDomain: values.myshopifyDomain,
          brandId: props.brand.id,
        }),
      }
    );

    console.log(res)
  }

  return (
    <AdminScreen headline={"Shopify Config"}>
      <Grid>
        <Grid.Unit size={{ sm: 12 / 12 }}>
          <Padding all={2}>
            <Box>
              <Padding all={2}>
                <Grid>
                  {configStatus && (
                    <Grid.Unit size={{ sm: 1, md: 1 }}>
                      <Padding all={2}>{configStatus}</Padding>
                    </Grid.Unit>
                  )}

                  <Grid.Unit size={{ sm: 1, md: 1 }}>
                    <Padding all={2}>
                      <TextInput
                        inputProps={{
                          ref: register({}),
                          type: "text",
                          name: "myshopifyDomain",
                          placeholder: "your-store.myshopify.com",
                        }}
                        label="Enter your myshopify.com domain"
                      />
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 1 }}>
                    <Padding all={2}>
                      <TextInput
                        inputProps={{
                          ref: register({}),
                          type: "password",
                          name: "accessToken",
                        }}
                        label="Enter your Shopify admin API access token"
                      />
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 1 }}>
                    <Padding all={2}>
                      <TextInput
                        inputProps={{
                          ref: register({}),
                          type: "text",
                          name: "shopifyApiKey",
                        }}
                        label="Enter your Shopify app API key"
                      />
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 1 }}>
                    <Padding all={2}>
                      <TextInput
                        inputProps={{
                          ref: register({}),
                          type: "password",
                          name: "shopifyApiSecret",
                        }}
                        label="Enter your Shopify app API secret key"
                      />
                    </Padding>
                  </Grid.Unit>
                  <Grid.Unit size={{ sm: 1, md: 1 }}>
                    <Padding all={2}>
                      <ButtonHollow
                        style={
                          enteredShopifyDomain === "" ||
                          enteredAccessToken === "" ||
                          enteredShopifyApiKey === "" ||
                          enteredShopifyApiSecret === ""
                            ? {
                                pointerEvents: "none",
                                border: "1px solid #334450",
                                color: "#334450",
                              }
                            : {}
                        }
                        onClick={save}
                      >
                        Save
                      </ButtonHollow>
                    </Padding>
                  </Grid.Unit>
                  {isConfigured && (
                    <Grid.Unit size={{ sm: 1, md: 1 }}>
                      <Padding all={2}>
                        <ButtonHollow onClick={getOrderData}>
                          Get Orders
                        </ButtonHollow>
                        <div>
                        <ButtonHollow onClick={checkBulkOpStatus}>
                          Check bulk operation status
                        </ButtonHollow>
                        </div>
                      </Padding>
                    </Grid.Unit>
                  )}
                </Grid>
              </Padding>
            </Box>
          </Padding>
        </Grid.Unit>
      </Grid>
    </AdminScreen>
  );
}

export function ShopifyConfigLoader(props) {
  const { data, loading, error } = useQuery(LOAD_BRAND_SHOPIFY_CONFIG, {
    variables: {
      id: props.match.params.brand,
    },
  });

  if (loading || !data) return null;
  if (error) return null;

  return <ShopifyConfig history={props.history} brand={data.brand} />;
}

export default ShopifyConfigLoader;
